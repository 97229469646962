exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-huni-feedback-js": () => import("./../../../src/pages/huni-feedback.js" /* webpackChunkName: "component---src-pages-huni-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-leeloo-feedback-js": () => import("./../../../src/pages/leeloo-feedback.js" /* webpackChunkName: "component---src-pages-leeloo-feedback-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-licenses-js": () => import("./../../../src/pages/licenses.js" /* webpackChunkName: "component---src-pages-licenses-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-privacypolicy-textonly-js": () => import("./../../../src/pages/privacypolicy_textonly.js" /* webpackChunkName: "component---src-pages-privacypolicy-textonly-js" */),
  "component---src-pages-tenta-feedback-js": () => import("./../../../src/pages/tenta-feedback.js" /* webpackChunkName: "component---src-pages-tenta-feedback-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

